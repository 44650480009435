import { motion, MotionProps } from 'framer-motion'
import styled from 'styled-components'

type Props = {
  title: string
  subtitle: string
} & MotionProps

function Tag({ title, subtitle, ...motionProps }: Props) {
  return (
    <$Tag {...motionProps}>
      <$Dot />
      <$TagInfo>
        <$TagTitle>{title}</$TagTitle>
        <$TagSubtitle>{subtitle}</$TagSubtitle>
      </$TagInfo>
    </$Tag>
  )
}

export default Tag

const $Dot = styled.span`
  height: 0.5rem;
  min-width: 0.5rem;
  background-color: var(--theme-black);
  border-radius: 50%;
`
const $TagInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`
const $TagTitle = styled.span`
  font-size: 13px;
  line-height: 1.2;
  margin-bottom: 0.25rem;
`
const $TagSubtitle = styled.span`
  font-weight: 600;
  font-size: 11px;
  line-height: 1.3;
`
const $Tag = styled(motion.div)`
  --side-padding: 1.125rem;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(14px);
  border-radius: 40px;
  padding: 0.5rem var(--side-padding);
  width: max-content;
  pointer-events: none;

  @media (max-width: 768px) {
    max-width: 300px;
  }

  ${$Dot} {
    margin-right: 0.875rem;
  }
`
