import { ReactNode } from 'react'
import styled from 'styled-components'
import { PopupButton } from '@typeform/embed-react'

type Props = {
  children: ReactNode
  typeformId: string
  className?: string
}

function PartnerPopupButton({ className, typeformId, children }: Props) {
  return (
    <$Button id={typeformId} className={className}>
      {children}
    </$Button>
  )
}

export default PartnerPopupButton

const $Button = styled(PopupButton)`
  background: none;
  border: none;
  text-decoration: none;
  color: inherit;
  padding: 0;
  font-weight: inherit;
`
