import styled from 'styled-components'

import { ReactComponent as RightAngle } from '../assets/right-angle.svg'
import { SOMETHING_TOKEN_URL } from '../constants'
import Button from './Button'

function CallToAction() {
  return (
    <$Container>
      <p className="bottom__text">
        The Something Token signifies your exclusive membership to the Americana
        ecosystem.
      </p>
      <Button href={SOMETHING_TOKEN_URL} target="_blank" rel="noreferrer">
        Get Early Access
        <$IconContainer>
          <RightAngle />
        </$IconContainer>
      </Button>
    </$Container>
  )
}

export default CallToAction

const $Container = styled.section`
  display: flex;
  align-items: flex-start;
  gap: 22px;
  margin: 0 auto;
  width: 100%;
  max-width: 915px;
  padding: 0 1.5rem 3.75rem 1.5rem;
  font-size: 1.25rem;
  font-weight: var(--theme-font-weight);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
  }
  @media (max-width: 480px) {
    font-size: 1rem;
    line-height: 1.25;

    ${Button} {
      width: 100%;
      justify-content: space-between;
      padding: 1.25rem 1.8rem;
    }
  }
`

const $IconContainer = styled.span`
  height: 19px;
  margin-left: 1.25rem;
`
