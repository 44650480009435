import create from "zustand";

type StoreState = {
  cursorVariant: string;
  setCursorVariant: (variant: string) => void
}

export const useStore = create<StoreState>(set => ({
  cursorVariant: 'default',
  setCursorVariant: (variant) => set({cursorVariant: variant})
}))