import styled from 'styled-components'

import { $Heading } from './subComponents'
import { ReactComponent as TwitterSvg } from '../../assets/twitter.svg'
import { ReactComponent as DiscordSvg } from '../../assets/discord.svg'
import { ReactComponent as OpenSeaSvg } from '../../assets/opensea.svg'
import {
  CAREERS_TYPEFORM_ID,
  DISCORD_URL,
  OPENSEA_SOMETHING_TOKEN_URL,
  TWITTER_URL,
} from '../../constants'
import TypeformPopupButton from '../TypeformPopupButton'

function Links() {
  return (
    <$Links>
      <$Social>
        <$Heading>Community</$Heading>
        <$LinkList>
          <li>
            <a href={TWITTER_URL} target="_blank" rel="noreferrer">
              <TwitterSvg />
              Twitter
            </a>
          </li>
          <li>
            <a href={DISCORD_URL} target="_blank" rel="noreferrer">
              <DiscordSvg />
              Discord
            </a>
          </li>
          <li>
            <a
              href={OPENSEA_SOMETHING_TOKEN_URL}
              target="_blank"
              rel="noreferrer"
            >
              <OpenSeaSvg />
              OpenSea
            </a>
          </li>
        </$LinkList>
      </$Social>
      <$Legal>
        <$LegalList>
          <li>
            <TypeformPopupButton typeformId={CAREERS_TYPEFORM_ID}>
              Careers
            </TypeformPopupButton>
          </li>
          <li>Legal</li>
          <li>TOS</li>
          <li>© Americana Technologies Inc 2022</li>
        </$LegalList>
      </$Legal>
    </$Links>
  )
}

export default Links

const $Links = styled.section`
  grid-area: links;
  position: relative;
  display: flex;
  border-top: 1px solid currentColor;

  @media (min-width: 1101px) {
    align-items: center;
    padding: 2rem var(--container-horizontal-padding);
  }
  @media (max-width: 1100px) {
    flex-direction: column;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  @media (min-width: 1440px) {
    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 100%;
      width: 100vw;
      border-top: 1px solid currentColor;
    }
  }
`

const $LinkList = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  font-size: 13px;

  @media (min-width: 481px) {
    gap: 1.5rem;
  }
  @media (max-width: 480px) {
    flex-direction: column;
  }

  & > li a {
    display: flex;
    align-items: center;

    @media (max-width: 480px) {
      padding: 0.75rem 0;
    }

    svg {
      margin-right: 0.5rem;
    }
  }
`

const $Social = styled.div`
  @media (min-width: 481px) {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
  }
  @media (max-width: 480px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1101px) {
    padding-right: 2rem;
  }
  @media (max-width: 1100px) {
    padding: var(--container-vertical-padding)
      var(--container-horizontal-padding);
    border-bottom: 1px solid currentColor;
  }

  ${$Heading} {
    margin-right: 2rem;
  }
`

const $Legal = styled.div`
  font-size: 9px;
  letter-spacing: -0.02em;

  @media (max-width: 1100px) {
    padding: 1.25rem var(--container-horizontal-padding) 1rem
      var(--container-horizontal-padding);
  }
`

const $LegalList = styled.ul`
  display: flex;
  align-items: center;
  gap: 1rem;
  list-style-type: none;
  padding: 0;
`
