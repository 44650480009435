import { useEffect, useState, type MouseEventHandler } from 'react'
import styled from 'styled-components'
import { useWindowWidth } from '@react-hook/window-size'
import Marquee from 'react-fast-marquee'

import { type TagInfo } from '../App'
import offwhiteSrc from '../assets/hero-a.png'
import teslaSrc from '../assets/hero-b.png'
import chanelSrc from '../assets/hero-c.png'
import picassoSrc from '../assets/hero-d.png'
import wegnerSrc from '../assets/hero-e.png'
import kanyeSrc from '../assets/hero-f.png'
import charizardSrc from '../assets/charizard.png'
import Tag from './Tag'
import { ETH_PRICE_API_URL } from '../constants'

type SlideInfo = {
  brand: string
  description: string
  usd: number
  src: string
}

const slides: SlideInfo[] = [
  {
    brand: 'Off-White',
    description: 'Industrial Hi Top',
    usd: 829,
    src: offwhiteSrc,
  },
  {
    brand: 'Tesla Model X',
    description: 'Battery electric mid-size luxury crossover',
    usd: 114990,
    src: teslaSrc,
  },
  {
    brand: 'Chanel',
    description: 'Double Flap Quilted shoulder bag',
    usd: 4000,
    src: chanelSrc,
  },
  {
    brand: 'Pablo Picasso',
    description: 'Figures at the Seaside',
    usd: 140000000,
    src: picassoSrc,
  },
  {
    brand: 'Hans Wegner',
    description: 'CH07 Shell Chair',
    usd: 3745,
    src: wegnerSrc,
  },
  {
    brand: 'Gap x Kanye West',
    description: 'YZY Gap FW21 Round Jacket Blue',
    usd: 250,
    src: kanyeSrc,
  },
  {
    brand: 'Pokemon',
    description: 'Base Set PSA 9 1st Ed. Charizard',
    usd: 31980,
    src: charizardSrc,
  },
]

type Props = {
  onSlideMouseEnter?: MouseEventHandler
  onSlideMouseLeave?: MouseEventHandler
}

function Carousel({ onSlideMouseEnter, onSlideMouseLeave }: Props) {
  const windowWidth = useWindowWidth()
  const isSmallScreen = windowWidth < 769
  const ethPrice = useCurrentEthPrice()

  const handleSlideMouseEnter: MouseEventHandler<HTMLDivElement> = (event) => {
    onSlideMouseEnter?.(event)
  }
  const handleSlideMouseLeave: MouseEventHandler<HTMLDivElement> = (event) => {
    onSlideMouseLeave?.(event)
  }

  return (
    <div>
      <Marquee speed={40} gradient={false}>
        {slides.map((slide) => (
          <Slide
            key={slide.src}
            onMouseEnter={handleSlideMouseEnter}
            onMouseLeave={handleSlideMouseLeave}
            isMobile={isSmallScreen}
            title={`${slide.brand} - ${slide.description}`}
            subtitle={`${
              formatUsdToEth(slide.usd, ethPrice).toFixed(2) ?? '--'
            } ETH / ${formatUsd(slide.usd)}`}
          >
            <$SlideImg src={slide.src} />
          </Slide>
        ))}
      </Marquee>
    </div>
  )
}

export default Carousel

const useCurrentEthPrice = () => {
  const [ethPrice, setEthPrice] = useState(0)
  useEffect(() => {
    fetch(ETH_PRICE_API_URL)
      .then((response) => response.json())
      .then((data) => setEthPrice(data.ethereum.usd))
  }, [])

  return ethPrice
}

const formatUsd = (value: number) =>
  Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
    value
  )

const formatUsdToEth = (usd: number, currentEthPrice = 3000) =>
  usd / currentEthPrice

type SlideProps = {
  children: React.ReactNode
  onMouseEnter?: MouseEventHandler<HTMLDivElement>
  onMouseLeave?: MouseEventHandler<HTMLDivElement>
  isMobile: boolean
} & TagInfo

function Slide({
  children,
  title,
  subtitle,
  onMouseEnter,
  onMouseLeave,
  isMobile,
}: SlideProps) {
  return (
    <$Slide
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-title={title}
      data-subtitle={subtitle}
    >
      {children}
      {isMobile ? <$Tag title={title} subtitle={subtitle} /> : null}
    </$Slide>
  )
}

const $Tag = styled(Tag)`
  position: absolute;
  bottom: 20%;
  left: 50%;
  transition: opacity 0.2s ease-in;
  z-index: 1;
`

const $Slide = styled.div`
  --slide-spacing: clamp(2rem, 6vw, 6.25rem);
  position: relative;
  margin: 0 var(--slide-spacing);

  &:not(:hover) {
    ${$Tag} {
      opacity: 0;
    }
  }
`

const $SlideImg = styled.img`
  filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.25));
  pointer-events: none;
  user-select: none;
`
