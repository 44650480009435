import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as LogoSvg } from '../../assets/logo.svg'
import { useStore } from '../../store'
import {
  AMERICANA_URL,
  PARTNER_TYPEFORM_ID,
  SOMETHING_TOKEN_URL,
} from '../../constants'
import NavMenu from './NavMenu'
import TypeformPopupButton from '../TypeformPopupButton'

function Nav() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const setCursorVariant = useStore((state) => state.setCursorVariant)

  const handleMenuToggle = () => {
    setIsMenuOpen((prev) => !prev)
  }
  const handleLinkMouseEnter = () => setCursorVariant('link')
  const handleLinkMouseLeave = () => setCursorVariant('default')

  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? 'hidden' : 'visible'
  }, [isMenuOpen])

  return (
    <>
      <NavMenu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
      <$Container>
        <$Header menuOpen={false}>
          <$LogoContainer>
            <$Link
              href={AMERICANA_URL}
              target="_blank"
              rel="noreferrer"
              onMouseEnter={handleLinkMouseEnter}
              onMouseLeave={handleLinkMouseLeave}
            >
              <LogoSvg />
            </$Link>
          </$LogoContainer>
          <$Nav>
            <$NavList menuOpen={false}>
              <$NavItem>
                <$NavLink
                  href={SOMETHING_TOKEN_URL}
                  target="_blank"
                  rel="noreferrer"
                  onMouseEnter={handleLinkMouseEnter}
                  onMouseLeave={handleLinkMouseLeave}
                >
                  <$Indicator>Something Token</$Indicator>
                </$NavLink>
              </$NavItem>
              <$NavItem>
                <div
                  onMouseEnter={handleLinkMouseEnter}
                  onMouseLeave={handleLinkMouseLeave}
                  style={{ display: 'flex', height: '100%' }}
                >
                  <$PopupButton typeformId={PARTNER_TYPEFORM_ID}>
                    Partner
                  </$PopupButton>
                </div>
              </$NavItem>
            </$NavList>
            <$MenuButton onClick={handleMenuToggle}>Menu</$MenuButton>
          </$Nav>
        </$Header>
      </$Container>
    </>
  )
}

export default Nav

const $Container = styled.div`
  border-bottom: 1px solid var(--theme-black);
  background-color: var(--theme-white);
`

const $Header = styled.header<{ menuOpen: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 480px) {
    padding: 1.3rem 0;
    ${({ menuOpen }) =>
      menuOpen
        ? `
      background-color: var(--theme-black);
      color: var(--theme-white);
    `
        : `
      color: var(--theme-black);
      svg {
        color: var(--theme-black);
      }
    `}
  }
`

export const $Indicator = styled.span`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    width: 8px;
    height: 8px;
    background-color: var(--theme-cobalt);
    border-radius: 100%;
    @media (max-width: 768px) {
      width: 5px;
      height: 5px;
    }
  }
`

const $LogoContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  padding: 0 3.125rem;

  @media (max-width: 1024px) {
    padding: 0 2rem;
  }
`

const $Nav = styled.nav`
  display: flex;
  background-color: inherit;
`

const $NavList = styled.ul<{ menuOpen: boolean }>`
  display: flex;
  list-style-type: none;
  padding: 0;
  background-color: inherit;

  @media (max-width: 480px) {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    flex-direction: column;
    border-bottom: 1px solid var(--theme-white);
    padding-top: 2rem;
    padding-bottom: 1.3rem;

    ${({ menuOpen }) =>
      !menuOpen &&
      `
      display: none;
    `}
  }
`

const $NavItem = styled.li`
  display: inherit;
  align-items: center;

  @media (min-width: 480px) {
    border-left: 1px solid var(--theme-black);

    &:last-of-type {
      border-right: 1px solid var(--theme-black);
    }
  }
`

const $Link = styled.a`
  text-decoration: none;
  color: inherit;
`

const $NavLink = styled($Link)`
  padding: 1.5rem 2.5rem;
  font-weight: var(--theme-font-weight);
  font-size: 20px;

  @media (max-width: 1024px) {
    padding: 1rem clamp(1rem, 3vw, 2rem);
  }
  @media (max-width: 768px) {
    font-size: 1rem;
  }
  @media (max-width: 480px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`

const $PopupButton = styled(TypeformPopupButton)`
  padding: 1.5rem 2.5rem;
  font-weight: var(--theme-font-weight);
  font-size: 20px;
  background: none;
  border: none;
  text-decoration: none;
  color: inherit;

  @media (max-width: 1024px) {
    padding: 1rem clamp(1rem, 3vw, 2rem);
  }
  @media (max-width: 768px) {
    font-size: 1rem;
    cursor: pointer;
  }
  @media (max-width: 480px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`

const $MenuButton = styled.button`
  padding: 0.5rem 1.25rem;
  border: none;
  background-color: transparent;
  color: inherit;
  outline: none;
  font-weight: var(--theme-font-weight);
  font-size: 1rem;
  line-height: 1.25;

  @media (min-width: 481px) {
    display: none;
  }
`
