import { useCallback } from 'react'
import styled from 'styled-components'

import { ReactComponent as LogoSvg } from '../../assets/logo.svg'
import { useStore } from '../../store'
import SubscribeForm from './SubscribeForm'
import Links from './Links'

function Footer() {
  const setCursorVariant = useStore(
    useCallback((state) => state.setCursorVariant, [])
  )
  const handleMouseEnter = () => setCursorVariant('defaultInverted')
  const handleMouseLeave = () => setCursorVariant('default')

  return (
    <$Container onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <$Footer>
        <$Main>
          <$LogoContainer>
            <LogoSvg />
          </$LogoContainer>
          <p>The first marketplace to buy and sell physical things as NFTs.</p>
        </$Main>

        <SubscribeForm />
        <Links />
      </$Footer>
    </$Container>
  )
}

export default Footer

const $Container = styled.div`
  background-color: var(--theme-black);
  color: var(--theme-white);

  @media (min-width: 1440px) {
    overflow-x: hidden;
  }
  @media (max-width: 480px) {
    display: none;
  }
`

const $Footer = styled.footer`
  --container-horizontal-padding: clamp(1.5rem, 3.3vw, 3rem);
  --container-vertical-padding: clamp(2.5rem, 3.3vw, 3rem);

  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: min-content min-content;
  grid-template-areas:
    'main form form'
    'main links links';
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, min-content);
    grid-template-areas:
      'main'
      'form'
      'links';
  }
`

const $LogoContainer = styled.div`
  @media (max-width: 768px) {
    margin-bottom: 5rem;
  }
`

const $Main = styled.section`
  grid-area: main;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--container-vertical-padding) var(--container-horizontal-padding);
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.4;

  @media (min-width: 769px) {
    border-right: 1px solid currentColor;
  }
  @media (max-width: 768px) {
    border-bottom: 1px solid currentColor;
  }

  & svg {
    width: 215px;
    height: 40px;
  }
`
