import { useCallback, useRef, useState, type MouseEventHandler } from 'react'
import { AnimatePresence, motion, Variant, type Variants } from 'framer-motion'
import useMouse from '@react-hook/mouse-position'
import styled from 'styled-components'
import { useWindowWidth } from '@react-hook/window-size'

import Nav from './components/Nav'
import Carousel from './components/Carousel'
import CallToAction from './components/CallToAction'
import Footer from './components/Footer'
import { useStore } from './store'
import Tag from './components/Tag'

export type TagInfo = { title: string; subtitle: string }
const initialTagInfo: TagInfo = { title: '', subtitle: '' }
const spring = {
  type: 'spring',
  mass: 0.0001,
}

function App() {
  const containerRef = useRef(null)
  const [tagInfo, setTagInfo] = useState<TagInfo>(initialTagInfo)
  const [isMouseActive, setIsMouseActive] = useState(false)
  const [isItemHovered, setIsItemHovered] = useState(false)

  const cursorVariant = useStore(
    useCallback((state) => state.cursorVariant, [])
  )
  const windowWidth = useWindowWidth()
  const isSmallScreen = windowWidth < 769
  const mouse = useMouse(containerRef, {
    enterDelay: 100,
    leaveDelay: 100,
    fps: 60,
  })

  const handleMouseEnter = () => setIsMouseActive(true)
  const handleMouseLeave = () => setIsMouseActive(false)
  const handleSlideMouseEnter: MouseEventHandler<HTMLDivElement> = (event) => {
    setIsItemHovered(true)

    const { title, subtitle } = event.currentTarget.dataset
    if (title && subtitle) {
      setTagInfo({ title, subtitle })
    }
  }
  const handleSlideMouseLeave = () => {
    setIsItemHovered(false)
    setTagInfo(initialTagInfo)
  }

  const defaultVariant: Variant = {
    backgroundColor: 'rgba(0, 0, 0, 1)',
    opacity: 1,
    x: mouse.clientX ?? 0,
    y: mouse.clientY ?? 0,
    transition: spring,
    top: '-4px',
    left: '-4px',
    height: '8px',
    width: '8px',
  }

  const variants: Variants = {
    hidden: {
      opacity: 0,
    },
    default: defaultVariant,
    defaultInverted: {
      ...defaultVariant,
      backgroundColor: 'rgba(255, 255, 255, 1)',
    },
    link: {
      ...defaultVariant,
      backgroundColor: 'rgba(255, 255, 255, 1)',
      width: '30px',
      height: '30px',
      top: '-15px',
      left: '-15px',
      mixBlendMode: 'difference',
    },
  }

  return (
    <$AppContainer
      ref={containerRef}
      onMouseEnter={isSmallScreen ? undefined : handleMouseEnter}
      onMouseLeave={isSmallScreen ? undefined : handleMouseLeave}
    >
      {isSmallScreen ? null : (
        <AnimatePresence>
          {isMouseActive ? (
            <$Cursor
              variants={variants}
              animate={cursorVariant}
              initial="hidden"
              exit="hidden"
            >
              <AnimatePresence>
                {isItemHovered ? (
                  <$Tag
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    title={tagInfo.title}
                    subtitle={tagInfo.subtitle}
                  />
                ) : null}
              </AnimatePresence>
            </$Cursor>
          ) : null}
        </AnimatePresence>
      )}
      <Nav />
      <$Main>
        <$Hero>
          <$Headline>Buy & Sell Physical Things as NFTs.</$Headline>
          <Carousel
            onSlideMouseEnter={
              isSmallScreen ? undefined : handleSlideMouseEnter
            }
            onSlideMouseLeave={
              isSmallScreen ? undefined : handleSlideMouseLeave
            }
          />
        </$Hero>
        <CallToAction />
      </$Main>
      <Footer />
    </$AppContainer>
  )
}

export default App

const $AppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const $Main = styled.main`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const $Tag = styled(Tag)`
  --side-padding: 1.125rem;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(calc(var(--side-padding) * -1), calc(-50% + 0.25rem));
`

const $Cursor = styled(motion.div)`
  position: fixed;
  border-radius: 100%;
  pointer-events: none;
  z-index: 100;
`

const $Hero = styled.section`
  --fluid-margin-bottom: clamp(0rem, 7vw, 7rem);
  margin-bottom: var(--fluid-margin-bottom);
`

const $Headline = styled.h1`
  --fluid-bottom-margin: clamp(-20rem, var(--fluid-margin-vw), -4.5rem);
  --fluid-margin-vw: -25vw;
  @media (max-width: 1024px) {
    --fluid-margin-vw: -19vw;
  }

  max-width: 118rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--fluid-bottom-margin);
  font-weight: var(--theme-font-weight);
  font-size: clamp(4.5rem, 2rem + 10.5vw, 16rem);
  line-height: 1;
  text-align: center;
  letter-spacing: -0.03em;
  user-select: none;
  z-index: 0;

  @media (min-width: 1025px) {
    padding: 0 2rem;
    margin-top: -1.25rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    margin-top: -0.75rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    margin-top: -0.25rem;
  }
`
