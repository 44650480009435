export const SOMETHING_TOKEN_URL = 'https://tokens.americana.io'
export const ETH_PRICE_API_URL =
  'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd'
export const MAILCHIMP_SUBSCRIBE_URL =
  'https://americana.us14.list-manage.com/subscribe/post?u=ad5ba3485a8ac73cc2d9a3c92&id=7c96122b2b'
export const TWITTER_URL = 'https://twitter.com/AmericanaNFT'
export const DISCORD_URL = 'https://discord.gg/americana'
export const OPENSEA_SOMETHING_TOKEN_URL =
  'https://opensea.io/collection/somethingtoken'
export const AMERICANA_URL = 'https://americana.io'
export const PARTNER_TYPEFORM_ID = 'TxSZb1WG'
export const CAREERS_TYPEFORM_ID = 'zyIFZ5Rh'
