import { AnimatePresence, motion, Variants } from 'framer-motion'
import styled, { css } from 'styled-components'

import { ReactComponent as LogoSvg } from '../../assets/logo.svg'
import {
  DISCORD_URL,
  OPENSEA_SOMETHING_TOKEN_URL,
  SOMETHING_TOKEN_URL,
  TWITTER_URL,
  PARTNER_TYPEFORM_ID,
  CAREERS_TYPEFORM_ID,
} from '../../constants'
import { ReactComponent as TwitterSvg } from '../../assets/twitter.svg'
import { ReactComponent as DiscordSvg } from '../../assets/discord.svg'
import { ReactComponent as OpenSeaSvg } from '../../assets/opensea.svg'
import SubscribeForm from '../Footer/SubscribeForm'
import TypeformPopupButton from '../TypeformPopupButton'
import { $Indicator } from './Nav'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const containerVariants: Variants = {
  initial: {
    translateY: '-100%',
  },
  open: {
    translateY: 0,
    transition: {
      when: 'beforeChildren',
      duration: 0.75,
    },
  },
  close: {
    translateY: '-100%',
    transition: {
      when: 'afterChildren',
      duration: 0.5,
    },
  },
}
const headerVariants: Variants = {
  initial: {
    translateY: 0,
  },
  close: {
    translateY: '-100%',
    transition: {
      duration: 1,
    },
  },
}
const navListVariants: Variants = {
  initial: {
    translateX: '-100%',
    opacity: 0,
  },
  open: {
    opacity: 1,
    translateX: 0,
    transition: {
      duration: 1,
    },
  },
}
const dividerVariants: Variants = {
  initial: {
    translateX: '-100%',
  },
  open: {
    translateX: 0,
    transition: {
      delay: 0.25,
      duration: 1,
    },
  },
}
const formVariants: Variants = {
  initial: {
    opacity: 0,
  },
  open: {
    opacity: 1,
    transition: {
      delay: 1,
    },
  },
}
const linksVariants: Variants = {
  initial: {
    opacity: 0,
  },
  open: {
    opacity: 1,
    transition: {
      delay: 1.5,
    },
  },
}

function NavMenu({ isOpen, onClose }: Props) {
  return (
    <AnimatePresence>
      {isOpen ? (
        <$Container>
          <$Body
            initial="initial"
            animate="open"
            exit="close"
            variants={containerVariants}
            isOpen={isOpen}
          >
            <$Header variants={headerVariants}>
              <LogoSvg width={230} height={36} />
              <$MenuButton onClick={onClose}>Close</$MenuButton>
            </$Header>

            <motion.div
              exit={{
                opacity: 0,
                translateY: '10%',
                transition: { duration: 1 },
              }}
            >
              <$NavList variants={navListVariants}>
                <$NavItem>
                  <$NavLink
                    href={SOMETHING_TOKEN_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <$Indicator>Something Token</$Indicator>
                  </$NavLink>
                </$NavItem>
                <$NavItem style={{}}>
                  <$PopupButton typeformId={PARTNER_TYPEFORM_ID}>
                    Partner
                  </$PopupButton>
                </$NavItem>
              </$NavList>

              <$Divider variants={dividerVariants} />

              <$FormContainer variants={formVariants}>
                <SubscribeForm />
              </$FormContainer>

              <motion.div variants={linksVariants}>
                <$Social>
                  <$Heading>Community</$Heading>
                  <$LinkList>
                    <li>
                      <a href={TWITTER_URL} target="_blank" rel="noreferrer">
                        <TwitterSvg />
                        Twitter
                      </a>
                    </li>
                    <li>
                      <a href={DISCORD_URL} target="_blank" rel="noreferrer">
                        <DiscordSvg />
                        Discord
                      </a>
                    </li>
                    <li>
                      <a
                        href={OPENSEA_SOMETHING_TOKEN_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <OpenSeaSvg />
                        OpenSea
                      </a>
                    </li>
                  </$LinkList>
                </$Social>

                <$Legal>
                  <$LegalList>
                    <li>
                      <TypeformPopupButton typeformId={CAREERS_TYPEFORM_ID}>
                        Careers
                      </TypeformPopupButton>
                    </li>
                    <li>Legal</li>
                    <li>TOS</li>
                    <li>© Americana Technologies Inc 2022</li>
                  </$LegalList>
                </$Legal>
              </motion.div>
            </motion.div>
          </$Body>
        </$Container>
      ) : null}
    </AnimatePresence>
  )
}

export default NavMenu

const $Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
`

const $Body = styled(motion.div)<{ isOpen: boolean }>`
  --menu-x-padding: 1.5rem;
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: var(--theme-black);
  color: var(--theme-white);
  overflow-y: scroll;
  overflow-y: ${({ isOpen }) => (isOpen ? 'scroll' : 'hidden')};
  ${({ isOpen }) => (isOpen ? null : 'pointer-events: none')}
`

const $MenuButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0.5rem;
  margin-bottom: -0.75rem;
`

const sectionPadding = css`
  padding-left: var(--menu-x-padding);
  padding-right: var(--menu-x-padding);
`
const sectionBorder = css`
  border-top: 1px solid currentColor;
`

const $Divider = styled(motion.hr)`
  width: 100%;
  border: 0;
  border-top: 1px solid currentColor;
`

const $Header = styled(motion.header)`
  ${sectionPadding}
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
`

const $NavLink = styled.a`
  text-decoration: none;
  color: inherit;
  font-weight: var(--theme-font-weight);
`
const $PopupButton = styled(TypeformPopupButton)``

const $NavList = styled(motion.ul)`
  ${sectionPadding}
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style-type: none;
  line-height: 1.25;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-weight: var(--theme-font-weight);

  ${$NavLink}, ${$PopupButton} {
    padding: 0.5rem 0;
  }
`

const $NavItem = styled.li``

const $FormContainer = styled(motion.div)`
  ${sectionPadding}
  padding-top: 5rem;
  padding-bottom: 2.75rem;
`

const $Heading = styled.h2`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
`

const $LinkList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  font-size: 13px;

  & > li a {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    padding: 0.75rem 0;

    svg {
      margin-right: 0.5rem;
    }
  }
`

const $Social = styled.div`
  ${sectionPadding}
  ${sectionBorder}
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding-top: 2.75rem;
  padding-bottom: 3.25rem;
`

const $Legal = styled.div`
  ${sectionPadding}
  ${sectionBorder}
  font-size: 9px;
  letter-spacing: -0.02em;
  padding-top: 20px;
  padding-bottom: 30px;
`

const $LegalList = styled.ul`
  display: flex;
  gap: 1rem;
  list-style-type: none;
  padding: 0;
`
