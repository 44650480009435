import styled from 'styled-components'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

import { MAILCHIMP_SUBSCRIBE_URL } from '../../constants'
import { ReactComponent as RightAngleSvg } from '../../assets/right-angle.svg'
import { $Heading } from './subComponents'

function SubscribeForm() {
  return (
    <$FormContainer>
      <MailchimpSubscribe
        url={MAILCHIMP_SUBSCRIBE_URL}
        render={({ subscribe, status, message }) => (
          <div>
            <$Heading>Get the Latest</$Heading>
            <$Form
              onSubmit={(event) => {
                event.preventDefault()

                const formData = new FormData(event.currentTarget)
                const fields = Object.fromEntries(formData)
                subscribe({ EMAIL: fields.email as string })
                event.currentTarget.reset()
              }}
            >
              <$Input
                type="email"
                name="email"
                id="email"
                placeholder="Email Address"
              />
              <$SubmitButton>
                <RightAngleSvg width={5} />
              </$SubmitButton>
            </$Form>
            <p>
              Join our mailing list to stay up to date on the latest features
              and drops.
            </p>
            {status === 'sending' && <div>sending...</div>}
            {status === 'error' && (
              <div dangerouslySetInnerHTML={{ __html: message as string }} />
            )}
            {status === 'success' && <div>Subscribed !</div>}
          </div>
        )}
      />
    </$FormContainer>
  )
}

export default SubscribeForm

const $Form = styled.form`
  display: inline-flex;
  align-items: center;
  margin-bottom: 1.6rem;
  border-bottom: 1px solid currentColor;
  min-width: 270px;
`

const $Input = styled.input`
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  color: inherit;
  padding: 1rem 0;

  &::placeholder {
    opacity: 0.5;
  }
`

const $SubmitButton = styled.button`
  position: relative;
  width: 1.75rem;
  height: 1.75rem;
  background: transparent;
  border-radius: 50%;
  border: 1px solid currentColor;
  color: inherit;

  & > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
`

const $FormContainer = styled.section`
  grid-area: form;
  padding: var(--container-vertical-padding) var(--container-horizontal-padding);
  font-size: 13px;

  ${$Heading} {
    margin-bottom: 0.75rem;
  }
`
