import styled from 'styled-components'

const Button = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  padding: 1rem 1.3rem;
  border-radius: 50px;
  color: var(--theme-white);
  text-decoration: none;
  line-height: 1.2;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  transform: translateZ(0);

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &::before {
    background-color: var(--theme-cobalt);
    z-index: -1;
  }
  &::after {
    background-color: var(--theme-black);
    transition: transform 0.75s ease-in-out;
    z-index: -1;
  }
  &:not(:hover)::after {
    transform: translateX(-100%);
  }
`

export default Button
